import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode, useRef, useState } from 'react';
import { useEffectOnce, useToggle } from 'react-use';
import { cancelSubscription, defaultStatuses, getPaymentsItems, GetPaymentsResponse } from '..';
import { DigitalSubscriptionDetails } from './Digital';

export interface StandaloneSubscriptionDetailsDigitalProps
  extends StandaloneComponentProps<typeof DigitalSubscriptionDetails> {
  headline?: ReactNode;
  description?: ReactNode;
  contactInfo?: ReactNode;
  history?: ComponentProps<typeof DigitalSubscriptionDetails.History>;
  confirmationDialog?: ComponentProps<typeof DigitalSubscriptionDetails.Dialog>;
  cancelSubscriptionButton?: ComponentProps<typeof DigitalSubscriptionDetails.Button>;
  paymentHistoryButton?: ComponentProps<typeof DigitalSubscriptionDetails.Button>;
}

export const StandaloneSubscriptionDetailsDigital: StandaloneComponent<StandaloneSubscriptionDetailsDigitalProps> = ({
  headline,
  description,
  contactInfo,
  confirmationDialog,
  cancelSubscriptionButton,
  paymentHistoryButton,
  history,
  ...props
}) => {
  const [isHistoryOpen, toggleIsHistoryOpen] = useToggle(false);
  const cancelConfirmationDialogRef = useRef<HTMLDialogElement>(null);
  const [isCancellingSubscription, toggleIsCancellingSubscription] = useToggle(false);
  const [paymentHistory, setPaymentHistory] = useState<GetPaymentsResponse | undefined>();

  useEffectOnce(() => {
    async function fetchData() {
      const data = await getPaymentsItems();
      setPaymentHistory(data);
    }
    fetchData();
  });

  const showButtons = Boolean(cancelSubscriptionButton || paymentHistoryButton);

  return (
    <DigitalSubscriptionDetails {...props}>
      {headline && <DigitalSubscriptionDetails.Headline>{headline}</DigitalSubscriptionDetails.Headline>}
      {description && <DigitalSubscriptionDetails.Description>{description}</DigitalSubscriptionDetails.Description>}
      <DigitalSubscriptionDetails.DetailsList>
        {paymentHistory?.startDate && (
          <DigitalSubscriptionDetails.Detail>
            Påbörjad: <b>{paymentHistory.startDate}</b>
          </DigitalSubscriptionDetails.Detail>
        )}
        {paymentHistory?.endDate && (
          <DigitalSubscriptionDetails.Detail>
            Avslutas: <b>{paymentHistory.endDate}</b>
          </DigitalSubscriptionDetails.Detail>
        )}
        <DigitalSubscriptionDetails.Detail>
          Status: <b>{paymentHistory?.status ? defaultStatuses[paymentHistory.status] : 'Okänd'}</b>
        </DigitalSubscriptionDetails.Detail>
      </DigitalSubscriptionDetails.DetailsList>
      {showButtons && (
        <DigitalSubscriptionDetails.ButtonGroup>
          {cancelSubscriptionButton && (
            <DigitalSubscriptionDetails.Button
              {...mergeProps(
                {
                  options: mergeOptions(
                    {
                      onClick: () => cancelConfirmationDialogRef.current?.showModal(),
                      disabled: isCancellingSubscription || paymentHistory?.status === 'cancelled',
                    },
                    props.options?.$button,
                  ),
                },
                cancelSubscriptionButton,
              )}
            />
          )}
          {paymentHistoryButton && (
            <DigitalSubscriptionDetails.Button
              {...mergeProps(
                {
                  options: mergeOptions(
                    { colors: 'secondary', onClick: () => toggleIsHistoryOpen() },
                    props.options?.$button,
                  ),
                },
                paymentHistoryButton,
              )}
            />
          )}
        </DigitalSubscriptionDetails.ButtonGroup>
      )}
      {contactInfo && <DigitalSubscriptionDetails.ContactInfo>{contactInfo}</DigitalSubscriptionDetails.ContactInfo>}
      {isHistoryOpen && history && (
        <DigitalSubscriptionDetails.History
          {...mergeProps(
            {
              items: paymentHistory?.payments?.map(({ date, description, amount, currency }) => ({
                date,
                description,
                price: `${amount / 100} ${currency}`,
              })),
            },
            { options: props.options?.$history },
            history,
          )}
        />
      )}
      {confirmationDialog && (
        <DigitalSubscriptionDetails.Dialog
          onPrimaryButtonClick={
            confirmationDialog.onPrimaryButtonClick ||
            (async () => {
              try {
                toggleIsCancellingSubscription();
                await cancelSubscription({
                  subscriptionId: paymentHistory?.subscriptionId || '',
                });
                setPaymentHistory(await getPaymentsItems());
                toggleIsCancellingSubscription();
              } catch (error) {
                console.error('Error cancelling subscription:', error);
                toggleIsCancellingSubscription();
              }
            })
          }
          {...confirmationDialog}
          options={{ ref: cancelConfirmationDialogRef, ...confirmationDialog.options }}
        />
      )}
    </DigitalSubscriptionDetails>
  );
};
