import { ButtonTheme } from '@/components/Button/theme';
import { DialogTheme } from '@/components/Dialog/theme';
import { SubscriptionDetailsDigitalHistoryTheme } from '@/components/SubscriptionDetails/Digital/History/theme';
import { tw } from '@/utils/tw';

const DigitalSubscriptionDetailsTheme = tw.theme({
  slots: {
    base: [],
    headline: [],
    description: [],
    detailsList: [],
    detail: [],
    contactInfo: [],
    buttonGroup: [],
  },
});

export default Object.assign(DigitalSubscriptionDetailsTheme, {
  Button: ButtonTheme,
  History: SubscriptionDetailsDigitalHistoryTheme,
  Dialog: DialogTheme,
});
