import { Button as StandaloneButton } from '@/components/Button';
import { Dialog as StandaloneDialog } from '@/components/Dialog';
import { SubscriptionDetailsDigitalHistory as StandaloneSubscriptionDetailsDigitalHistory } from '@/components/SubscriptionDetails/Digital/History';
import { SubscriptionDetailsDigitalTheme } from '@/components/SubscriptionDetails/Digital/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SubscriptionDetailsDigitalTheme });

const Base = $({ as: 'div', slot: 'base' });
const ButtonGroup = $({ as: 'div', slot: 'buttonGroup' });
const ContactInfo = $({ as: 'p', slot: 'contactInfo' });
const Description = $({ as: 'p', slot: 'description' });
const Detail = $({ as: 'li', slot: 'detail' });
const DetailsList = $({ as: 'ul', slot: 'detailsList' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Button = GenericSlot({ as: StandaloneButton, theme: SubscriptionDetailsDigitalTheme.Button });
const History = GenericSlot({
  as: StandaloneSubscriptionDetailsDigitalHistory,
  theme: SubscriptionDetailsDigitalTheme.History,
});
const Dialog = GenericSlot({ as: StandaloneDialog, theme: SubscriptionDetailsDigitalTheme.Dialog });

export const DigitalSubscriptionDetails = Object.assign(Base, {
  ButtonGroup,
  ContactInfo,
  Description,
  Detail,
  DetailsList,
  Headline,
  Button,
  History,
  Dialog,
});
